import React, { Component } from 'react';
import { Link } from 'gatsby';
import BackgroundImage from '../../../../static/images/soccer_player.jpg';
import styles from './navigation-drawer.module.less';

export default class NavigationDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDrawer: this.props.showDrawer,
    };
  }

  render() {
    let styleImage = BackgroundImage && {
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ),url(${BackgroundImage}?q=60)`,
    };
    const menuItems = this.props.navigationItemList ? this.props.navigationItemList : [];
    return (
      <nav
        className={[styles.navigationDrawer, this.props.showDrawer && styles.navigationDrawerOpen].join(' ')}
        style={styleImage}>
        <ul className={styles.navigationDrawer__list}>
          {menuItems.length > 0 &&
            menuItems.map(listItem => {
              return (
                <li key={listItem.id} className={styles.navigationDrawer__listItem}>
                  <Link
                    className={styles.navigationDrawer__itemLink}
                    exact='true'
                    to={listItem.url.slug}
                    activeClassName={styles.active}
                    onClick={this.props.onItemClick}>
                    <span>{listItem.title}</span>
                  </Link>
                </li>
              );
            })}
        </ul>
      </nav>
    );
  }
}
